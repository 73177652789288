@media screen and (max-width: 1600px) {
  .sign-in-head {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }

  .login-head {
    background-position: 100% 32%;
    height: 100vh;
  }

  .login-form-head {
    margin-top: 4rem;
  }

  .form-logo {
    padding-top: 1rem;
    padding-bottom: 0.25rem;
    max-width: 60%;
    height: auto;
    margin: 0px auto;
  }

  .form-head-text {
    font-size: 34px;
    line-height: 25px;
  }
  .time-input {
    width: 55%;
  }
  .footer-text {
    position: fixed;
    bottom: 28px;
    left: 41%;
    right: 41%;
    margin-top: 0rem;
  }
  .tabs-customer .nav-link {
    min-height: 65px;
  }
}

@media screen and (max-width: 1400px) {
  .login-form-head {
    width: 45%;
  }
  .time-input {
    width: 70%;
  }
  .footer-text {
    position: fixed;
    left: 38%;
    right: 38%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .common-btnn {
    padding: 10px 10px;
    font-size: 13px;
  }

  .social-links {
    gap: 30px;
  }

  .profile-main {
    padding-right: 20px;
  }

  .social-links li:after {
    right: -12px;
  }
}
@media screen and (max-width: 1280px) {
  .login-form-head {
    margin-top: 0.5rem;
  }
}

@media (max-height: 635px) {
  .purchase-create-field {
    height: 355px;
    overflow-y: auto;
}
  .css-1g0np3u {
    height: 540px !important;
  }
  .css-15jxout {
    max-height: 540px !important;
}
.css-8bpm0a{
  max-height: 540px !important; 
}
}



@media screen and (max-width: 1200px) {
  .login-form-head {
    width: 54%;
  }
  .social-links {
    gap: 72px;
    justify-content: start !important;
  }

  .profile-text {
    width: 320px;
  }

  .profile-main {
    border-right: none;
  }
  .trainig-card-head-client {
    width: 22%;
  }
  .leads-table-responsive th, td {
    min-width: 200px;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .login-form-head {
    width: 73%;
  }

  .footer-head {
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
  }
  .footer-text {
    position: fixed;
    left: 28%;
    right: 28%;
  }
  .leads-table-responsive th,
  td {
    min-width: 180px;
    width: 100%;
  }
  .no-data-content {
    padding: 20px 0px;
  }
  .video-upload {
    margin-top: 30px;
  }
  .header-icons {
    display: inline-block;
  }
  .header-navheads {
    flex-direction: row;
    justify-content: flex-end;
  }
  .nav-top-head {
    display: flex;
  }
  .nav-input-search {
    margin-left: 20px !important;
  }

  .search-icon {
    position: absolute;
    top: 10px;
    left: 5px;
    max-width: 17px;
  }
  .dash-header {
    display: flex;
    flex-direction: row;
  }
  .tabs-customer {
    border-right: none;
  }
  .trainig-card-head {
    margin: 0px auto;
  }
  .header-dropdown-menu[data-bs-popper] {
    position: absolute;
  }
  .header-icons {
    padding: 10px;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 12px;
    padding-top: 8px;
  }
  .header-leads-btn {
    padding: 13px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .profile-main {
    padding-right: 0px;
  }
  .tabs-customer .nav-link {
    min-height: 55px;
  }
  .leads-table-responsive tr,
  th {
    min-width: 160px;
    width: 100%;
  }

  .days-head {
    border: 2px solid #d9d9d9;
  }
  .member-table-top {
    margin-top: 30px;
  }
  .custom-search-icon {
    left: 28px !important;
  }
}

@media screen and (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: wrap !important;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .login-form-head {
    width: 100%;
  }
  .nav-input-search {
    width: 300px;
  }
  .dashboard-search {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .dashboard-search .nav-input-search {
    width: 250px;
  }
  .dashboard-search .title-heading-container {
    width: 100%;
  }
  .dashboard-search .title-heading {
    justify-content: space-between;
  }
  .dashboard-search .custom-search-icon {
    left: 9px !important;
  }
  .dashboard-search .nav-input-search {
    margin-left: 0px !important;
  }

  .dashboard-search .custom-filter-dropdown {
    right: unset !important;
    left: 1rem;
  }
  .dash-header {
    display: inline-block;
  }
  .header-nav-link {
    border-right: none;
  }
  .header-nav-item {
    display: inline-block;
  }
  .header-nav-item a {
    padding-left: 5px !important;
  }
  .preview-docs {
    width: 150px;
    height: 220px;
    min-width: 150px;
  }
  .preview-head {
    padding: 0px 15px;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .preview-content {
    height: 100%;
    padding-bottom: 0px;
    margin: auto 0px;
    align-items: center;
  }
  .preview-docs-btn {
    left: 24%;
  }
  .header-img {
    max-width: unset;
    height: 18px;
    width: 18px;
  }
  .trainig-card-head-client {
    width: 30%;
  }
  .login-form-head {
    margin-top: 1.2rem;
  }
}
@media screen and (min-width: 576px) {
  .staff-list-mobile {
    min-width: 143px;
  }
  .vertical-heading{
    min-width: 450px!important;
  }
}

@media screen and (max-width: 576px) {
  .login-form-head {
    width: 80%;
  }
  .dashboard-search .dash-search-container {
    width: 100%;
  }

  .dashboard-search .title-heading {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: 1rem;
  }
  .dashboard-search .nav-input-search {
    width: 100%;
  }

  .form-head-text {
    font-size: 28px;
  }

  .login-logo {
    max-width: 75%;
    height: auto;
  }

  .login-form-head {
    margin-top: 0.5rem;
  }

  .profile-main {
    border-right: 0;
    padding-right: 0;
  }

  .social-links {
    gap: 42px;
  }

  .social-links li:after {
    right: -25px;
  }
  .footer-text {
    position: fixed;
    left: 10%;
    right: 10%;
  }
  .profile-change-pic {
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .input-profile {
    opacity: 0;
    position: absolute;
    top: 0px;
    z-index: 99;
  }

  .next-btn {
    width: 100%;
  }

  .common-bg {
    padding: 15px 0px;
  }
  .video-upload {
    height: 285px;
  }
  .video-file {
    height: 285px;
  }
  .video-upload {
    margin-top: 50px;
  }
  .preview-docs {
    height: 200px;
  }
  .header-nav-item {
    width: 33.3%;
    padding: 4px 0px;
  }

  .header-navheads {
    flex-direction: row;
    justify-content: flex-start;
  }

  .header-icons {
    padding: 10px;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .preview-docs-btn {
    left: 25%;
  }
  .nav-input-search {
    width: 100%;
  }
  .css-hriv81,
  .css-1getbjd {
    width: 94% !important;
  }
  .social-links {
    justify-content: space-between !important;
  }
  .profile-text {
    width: 100%;
  }
  .tabs-customer .nav-link {
    min-height: 50px;
  }
  .leads-listing {
    max-height: 350px;
    overflow-y: scroll;
  }
  .staff-list {
    max-height: 200px;
  }
  .staff-modal-head {
    width: 95% !important;
  }
  .remarktip .remarktiptext {
    max-width: 250px;
  }
  .cus-nav-input-search {
    margin-left: 0px !important;
  }
  .custom-search-icon {
    left: 10px !important;
  }

  .trainig-card-head-client {
    width: 100%;
  }
  .css-1g0np3u {
    width: 100% !important;
  }
  .css-8bpm0a{
    width: 100% !important;
  }
}

@media screen and (max-width: 440px) {
  .login-form-head {
    width: 95%;
  }

  .custom-filter-dropdown .slect-container {
    flex-direction: column;
    gap: 1rem !important;
  }

  .login-logo {
    max-width: 100%;
    height: auto;
  }

  .form-head-text {
    font-size: 22px;
    line-height: 30px;
  }

  .profile-main {
    display: block;
  }
  .days-content {
    padding: 20px 0px;
  }
  .header-nav-item {
    width: 50%;
  }
  .trainig-content {
    display: block !important;
  }
  .trainig-content p {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 380px) {
  .footer-text {
    position: fixed;
    bottom: 20px;
  }
  .header-logo {
    max-width: 75%;
    height: auto;
  }
  .days-content p {
    font-size: 12px !important;
  }
  .nav-top-head {
    display: inline-block;
    text-align: start;
  }
  .remarktip .remarktiptext {
    max-width: 200px;
  }
}
@media screen and (max-width: 320px) {
  .footer-text {
    position: fixed;
    bottom: 5px;
    left: 5%;
    right: 5%;
  }
  .trainig-card-head {
    width: 100%;
  }
  .training-demo {
    width: 100%;
  }
  .training-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .video-upload {
    margin-top: 30px;
  }
  .header-nav-item {
    width: 100%;
  }
  form.create-field {
    padding: 5px 20px;
  }
  .css-hriv81,
  .css-1getbjd {
    width: 94% !important;
  }
  .preview-docs {
    width: 125px;
    height: 180px;
    min-width: 125px;
  }
  .preview-docs-btn {
    left: 18%;
  }
}
@media screen and (min-width: 992px) {
  .attendence-table-responsive th,
  .attendence-table-responsive td {
    width: 100%;
    min-width: 160px;
  }
  .responsive-dashboard th ,
  .responsive-dashboard td{
    min-width: 300px;
    width: 100%;  
  }
}
